import '@module/LinkBox/LinkBox.js';
import { EventDispatcher, documentReady, getSearchBoxConfigFromUrl, getUrlVars, isValidEmail, l, qs, setUrlParameter, storedItemRead } from '@utils/toolbox';
import { ConnectApi } from '@js/plugins/connectApi';
import { RecentSearches } from '@module/RecentSearches/RecentSearches.js';
import { SearchBox } from '@module/SearchBox/SearchBox';
import { logShowresultClick } from '@utils/connectApiCalls';

window.index = {
	init () {
		const cheapest = document.getElementById('cheapest-destinations');
		const homeIntro = document.getElementById('home-intro-sections');

		// About handling events on a select input (departure from Stockholm / Malmö / Copenhagen
		// Only shown on mobile
		if (cheapest) {
			const select = cheapest.getElementsByTagName('select')[0];
			const categories = cheapest.getElementsByClassName('cheapestDestinations__category');

			select.addEventListener('change', (e) => {
				const toShow = categories[e.target.value];

				Array.prototype.forEach.call(categories, category => category.classList.add('hidden'));

				toShow.classList.remove('hidden');
			});
		}

		// Configure & render search box considering url parameters
		const urlVars = getUrlVars();
		const searchBoxConfig = urlVars ? getSearchBoxConfigFromUrl(urlVars) : {};
		const hasConfig = ('legs' in searchBoxConfig || 'passengers' in searchBoxConfig);
		const selectedPassengersView = ('passengers' in searchBoxConfig && 'youth' in searchBoxConfig.passengers) ? 'youth' : 'default';


		window.searchBox = new SearchBox({
			id: 'abSearchBox',
			dateSelector: document.getElementById('ab-dateselector-index'),
			config: hasConfig ? searchBoxConfig : null,
			selectedPassengersView,
			callbacks: {
				onPreviewReceived: (instance, previewGroup) => {
					if (urlVars?.filter) {
						instance.showresultURL = setUrlParameter(instance.showresultURL, 'filter', urlVars.filter);
					}
					if (previewGroup?.flights?.length) {
						instance.cancelSearch();
						location.href = instance.showresultURL;
					}
				},
				onSearchComplete: (instance) => {
					if (instance.data.forceNearbyDates) {
						instance.showresultURL = setUrlParameter(instance.showresultURL, 'forceNearbyDates', 1);
					}

					if (urlVars?.filter) {
						instance.showresultURL = setUrlParameter(instance.showresultURL, 'filter', urlVars.filter);
					}

					location.href = instance.showresultURL;
				}
			}
		});

		// Initiate search directly considering url parameters
		if ('youth' in urlVars) {
			EventDispatcher.dispatch('showSearchBoxYouthPassengers');
		} else if (urlVars?.initializeSearch) {
			EventDispatcher.dispatch('submitSearch');
		}

		// Instantiate recent searches
		window.recentSearches = new RecentSearches();

		logShowresultClick('list-item--price');

		// Event handlers on element only existing on Flygresor.se (Om oss section and newsletter)
		if (js_params.domain === 'flygresor.se') {
			const showMoreBtn = document.getElementById('home-info-show-more-btn');
			const newsForm = document.getElementById('news-letter-form');

			showMoreBtn.addEventListener('click', (e) => {
				const hiddenSections = homeIntro.getElementsByClassName('home-intro__section--hidden');

				Array.prototype.forEach.call(hiddenSections, (section) => {
					section.classList.remove('home-intro__section--hidden');
				});

				e.target.parentNode.style.display = 'none';
			});


			// About handling events for the newsletter subscription
			newsForm.addEventListener('submit', (e) => {
				e.preventDefault();

				const emailField = e.target.querySelector('#email-field');
				const emailAddress = emailField.value;

				if (isValidEmail(emailAddress)) {
					ConnectApi('NewsletterSubscription', 'subscribe', [emailAddress]).then((response) => {
						window.trackEvent.triggerGAevent('newsletter', 'registration');
						document.getElementById('news-letter-form').innerHTML = response;
					});
				} else {
					window.alert(l('pricewatcher.form.emailError'));
					e.target.querySelector('.input-field-section').classList.add('error');
					emailField.focus();
				}
			});
		}

		// Check if favorites were ever added to local storage
		const lS = storedItemRead('favorite');
		const indexFavouriteElement = qs('.index-fav-link');

		if (lS?.length && indexFavouriteElement) {
			indexFavouriteElement.style.display = 'flex';
		}
	}

};

documentReady(() => {
	window.index.init();
});
